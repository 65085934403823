import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Pagination from '@mui/material/Pagination';

import '../../App.css';
import AppSettings from '../../API/appSettings';
import { Item, Item2, Found } from '../../styles';
import translate from '../../services/Translation';
import { fetchPost } from '../../services/GettingData';
import Header from '../../components/HeaderComponent';
import MoleculeView from '../../components/MoleculeView';
import FilterComponent from '../../components/FilterComponent';
import InternalError from '../../schemas/Exception/InternalError';
import ProgressControl from '../../components/ProgressControl';
import HelpIconWithHint from '../../components/HelpIconWithHint';
import ISubSearchFilterModel from '../../schemas/Compound/ISubSearchFilterModel';
import { saveSubSearchFilterSettings } from '../../services/HandlingState';
import TimeoutError from '../../schemas/Exception/TimeoutError';
import RefreshError from '../../schemas/Exception/RefreshError';
import NotAuthorizedError from '../../schemas/Exception/NotAuthorizedError';
import ExpiredAccountError from '../../schemas/Exception/ExpiredAccountError';
import IHistoryPreviewModel from '../../schemas/History/IHistoryPreviewModel';
import { ICompoundSearchResponse } from '../../schemas/Compound/ICompoundSearchResponse';



type ICompoundHistoryWrapperProp = {
  location: {
    state: {
      historyPreview: IHistoryPreviewModel
    }
  }
};


export const CompoundHistoryPage: React.FC<ICompoundHistoryWrapperProp> = (props) => {

  const getHistorySVGs = (): string[] => {
    let svg = localStorage.getItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.HISTORY) || '';
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.HISTORY);
    return svg ? [svg] : []
  }


  const getHistoryMolecules = (): string[] => {
    let mol = localStorage.getItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.HISTORY) || '';
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.HISTORY);
    return mol ? [mol] : []
  }


  const loadFilterSettings = (): ISubSearchFilterModel => {
    let filters = {
      mol_query: '',
      compound_props: JSON.parse(localStorage.getItem('compound_props') || '[]'),
      show_radicals: false,
      iterate_tautomers: false,
      optional_mols: getHistoryMolecules(),
      nmr_types: JSON.parse(localStorage.getItem('nmr_types') || '[]'),
      optional_svgs: getHistorySVGs(),
    }
    return filters;
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [subSearchFilterModel, setSubSearchFilterModel] = React.useState<ISubSearchFilterModel>(loadFilterSettings());
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const [compoundSearchResponse, setCompoundSearchResponse] = React.useState<ICompoundSearchResponse>({
    items: [],
    total: 0,
    page: 1,
    size: 10,
    search_id: ''
  });


  useEffect(() => {
    setSubSearchFilterModel(prev => ({ ...prev, 
                                      from_search_id: (prev.from_search_id ? prev.from_search_id : compoundSearchResponse.search_id) }));
    console.log('useeffect on modification im compoundSearchResponse, .search_id=', compoundSearchResponse.search_id);
  }, [compoundSearchResponse]);


  const initialCombine = async () => {
    setIsLoading(true);
    try {
      const response = await fetchPost('/search/combine/' + props.location.state.historyPreview.method + '/compound?page=' + page + '&size=10',
        JSON.stringify(
          props.location.state.historyPreview.queries_ids), true, true);
      if (response.ok) {
        setCompoundSearchResponse(await response.json());
        setIsLoading(false);
      } else
        throw new InternalError();
    } catch (e) {
      if (e instanceof ExpiredAccountError)
        (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
      console.error('Error in combineAndOpenSub:', e);
    }
    finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (subSearchFilterModel.from_search_id)
      refreshSub(page);
    else
      initialCombine();
  }, [page]);


  const pageChangeHandle = async (event: React.ChangeEvent<unknown>, page_: number) => {
    setPage(page_);
  }


  const filterChanged = (filterModel: ISubSearchFilterModel) => {
    setSubSearchFilterModel(prev =>
    ({
      ...filterModel,
      mol_query: (subSearchFilterModel?.mol_query as string),
      optional_mols: (subSearchFilterModel?.optional_mols as string[]),
      optional_svgs: (subSearchFilterModel?.optional_svgs as string[]),
    }
    ))
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
    setIsLoading(true);
  }


  const searchAsSub = async (page: number): Promise<ICompoundSearchResponse> => {
    const endPoint = '/compound/search/sub?page=' + page + '&size=' + compoundSearchResponse?.size;
    try {
      const response = await fetchPost(endPoint, JSON.stringify(subSearchFilterModel), true, true);
      if (!response.ok) {
        showError(response.statusText);
        return { items: [], total: 0, page: 0, size: 0 }
      }
      return await response.json();
    }
    catch (e: any) {
      setIsLoading(false);
      if (e instanceof NotAuthorizedError)
        (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
      if (e instanceof ExpiredAccountError)
        (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
      if (e instanceof RefreshError) {
        showError(e.message);
        (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
      }
      if (e instanceof TimeoutError) {
        console.error(e.message);
        showError(e.message);
      }
      else
        showError(e.toString());
      return { items: [], total: 0, page: 0, size: 0 }
    }
  }


  const refreshSub = async (page: number = 1) => {
    setCompoundSearchResponse(await searchAsSub(page));
    setIsLoading(false);
  }


  const applyFilter = async (): Promise<void> => {
    setIsLoading(true);
    await refreshSub();
  }

  return (
    <Grid container spacing={0} className='main-frame'>
      <ProgressControl isLoading={isLoading} />
      <Grid md={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <Header title={props.location.state.historyPreview.method === 'union' ? 'Search Union' : 'Search Intersection'} showLogin={true} />

      <Grid container style={{ minWidth: "250px", marginTop: '4em' }} md={12} justifyContent="center">
        <Grid style={{ minWidth: "250px", marginLeft: '1em', marginRight: '1em', }}>
          <div>Extra filter</div>
          <Item style={{ width: "250px", marginTop: '12px' }} onClick={() => { saveSubSearchFilterSettings(subSearchFilterModel) }} >
            {<MoleculeView link={'/editor/history'}
              historyPreview={props.location.state.historyPreview}
              svgContent={subSearchFilterModel.optional_svgs[0] || ''}
              tooltip={'Open ketcher to draw substructure to filter results'}
              isMoleculeInContainer={true} />}
          </Item>
        </Grid>

        <Grid>
          <div style={{ marginBottom: '20px' }}><span className='box-title'>Filter</span></div>
          {<FilterComponent onChanged={filterChanged} subSearchFilterModel={subSearchFilterModel} />}
        </Grid>
      </Grid>
      <Grid md={12} justifyContent="center">
        <div style={{ marginBottom: '1em', marginTop: '2em', textAlign: 'center' }}>
          <Tooltip arrow title='Apply selected filters to your results'>
            <Button onClick={applyFilter} variant="contained">Apply Filter</Button>
          </Tooltip>
          <HelpIconWithHint title={''}
            text={'Apply selected filters to the current search results'}
            handleOpen={() => { }} />
        </div>

      </Grid>

      <Grid container md={12}>
        <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
        <Grid md={2} style={{ marginTop: '4em', padding: '1em' }}></Grid>
        <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
      </Grid>

      <Grid container md={12}>
        <Grid md={1}></Grid>
        <Grid md={5} sm={12} style={Found}>
          Found {compoundSearchResponse?.total} compounds
        </Grid>
        <Grid md={5}>
        </Grid>
        <Grid md={1}></Grid>
      </Grid>

      <Grid container md={12}>
        <Grid md={1}></Grid>
        <Grid container md={10} sm={12}>
          {
            compoundSearchResponse?.items &&
            compoundSearchResponse.items.map((compound, index) => (
              <Grid container md={12} sm={12}
                style={{ paddingTop: '0.7em' }}
                key={index} className='compound-feed'>

                <Grid md={3} sm={12}>
                  <MoleculeView link={''} moleculeId={compound.id}
                    svgContent={compound.svg}
                    isMoleculeInContainer={true}
                    inline={true}
                    moleculeString={compound.molecule}
                  />
                </Grid>
                <Grid container md={9} sm={12} xs={12}>
                  <Grid md={4} sm={4} xs={12}>
                    {compound.total_publications ? <div>Publications found: {compound.total_publications}</div> : null}
                  </Grid>
                  <Grid md={4} sm={4} xs={12}>
                    {
                      Object.entries(compound.properties).map(([key, value]) => {
                        return (value > 0 && <div key={key}>{translate(key)} found: {value}</div>)
                      })
                    }
                  </Grid>
                  <Grid md={4} sm={4} xs={12}>
                    {compound.associated_spectra && Object.entries(compound.associated_spectra).map((spectra, count) => (
                      <div key={spectra[0]}>{translate(spectra[0])} spectra found: {spectra[1]}</div>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
        <Grid md={1}></Grid>
      </Grid>

      <Grid md={12} sm={12} className='pagination-line' style={{ display: "inline" }} >
        <Grid style={{ width: '100%' }}>
          {compoundSearchResponse && compoundSearchResponse?.total > 0 &&
            <Pagination count={Math.ceil(compoundSearchResponse?.total / compoundSearchResponse?.size)}
              page={compoundSearchResponse?.page} onChange={pageChangeHandle} />}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CompoundHistoryPage;