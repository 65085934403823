import ISession from "../schemas/Login/ISession";
import { fetchPost, BearerPrefix } from "./GettingData";

import NotAuthorizedError from "../schemas/Exception/NotAuthorizedError";

const LOCAL_STORAGE_ODANCHEM_USER = 'odanchem_session_user';
const LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN = 'odanchem_session_access_token';
const LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN = 'odanchem_session_refresh_token';


function getSession(blockRefreshToken: boolean=false): ISession | undefined {
    const token = localStorage.getItem(LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN);
    if (!token) return undefined;
    const response = {
        access_token: localStorage.getItem(LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN) as string,
        refresh_token: localStorage.getItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN) as string,
        user: { firstname: localStorage.getItem(LOCAL_STORAGE_ODANCHEM_USER) as string }
    }
    if (blockRefreshToken) {
        localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN);
        console.log('refresh token has been removed from local storage');
    }
    return response;
}


function updSession(firstName: string) {
    localStorage.setItem(LOCAL_STORAGE_ODANCHEM_USER, firstName);
}


function keepSession(loginSession: ISession) {
    if (!loginSession) return;
    localStorage.setItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN, loginSession.refresh_token);
    localStorage.setItem(LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN, loginSession.access_token);
    localStorage.setItem(LOCAL_STORAGE_ODANCHEM_USER, loginSession.user.firstname);
}


async function closeSession(refreshToken: string) {
    await fetchPost('/user/account/logout', JSON.stringify({
            refresh_token: refreshToken
        }), false, true);
}


async function refreshToken(refreshToken: string = '', accessToken: string | undefined = undefined) {
    let session = getSession();
    if (!session)
        throw new NotAuthorizedError();

    if (accessToken && (BearerPrefix + session.access_token) !== accessToken) {
        console.warn('access token has already changed, ', accessToken);
        return;
    } 
    const response = await fetchPost('/user/token/refresh', JSON.stringify({
        refresh_token: session?.refresh_token
    }), false, false);

    if (response?.ok) {
        let newSession = await response.json();
        keepSession(newSession);
        console.log('refresh token has been updated');
    }
    else
        if (response?.status === 403) {
            let updatedSession = getSession();
            if (refreshToken && updatedSession!.refresh_token !== refreshToken) {
                console.warn('refresh token has already refreshed', refreshToken);
                return;
            }
            console.error('session token is up to date');
            throw new NotAuthorizedError();
        }
}


function logout() {
    closeSession(localStorage.getItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN) as string);
    localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_USER);
}

export { getSession, keepSession, logout, refreshToken, updSession };
