export enum SpectrumSearchMode {
    EXACT_MODE = 'exact',
    REACTION_MIXTURE_MODE = 'reaction_mixture',
    UNKNOWN_COMPOUND_MODE = 'unknown_compound',
  }

export function spectrumSearchModes() {
    return {
      [SpectrumSearchMode.EXACT_MODE]: SpectrumSearchMode.EXACT_MODE.replace('_', ' ').replace(/^./, str => str.toUpperCase()),
      [SpectrumSearchMode.REACTION_MIXTURE_MODE]: SpectrumSearchMode.REACTION_MIXTURE_MODE.replace('_', ' ').replace(/^./, str => str.toUpperCase()),
      [SpectrumSearchMode.UNKNOWN_COMPOUND_MODE]: SpectrumSearchMode.UNKNOWN_COMPOUND_MODE.replace('_', ' ').replace(/^./, str => str.toUpperCase()),
    };
  }