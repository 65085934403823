import * as React from 'react';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { Item } from '../styles';

interface ModalProps {
  isLoading: boolean;
  showTime?: boolean;
}

const ProgressControl: React.FC<ModalProps> = ({ isLoading, showTime = false }) => {
  const [seconds, setSeconds] = React.useState(0);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isLoading) {
      timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds < 120 ? prevSeconds + 1 : 120 );
      }, 1000);
    } else {
      setSeconds(0);
    }

    return () => clearInterval(timer);
  }, [isLoading]);

  return (
    <Modal
      aria-labelledby='loading-modal-title'
      open={isLoading}
      disableEscapeKeyDown
      component="div">
      <Item style={{
        padding: '3em',
        top: '50%',
        left: '50%',
        marginTop: '-10em',
        marginLeft: '-7em',
        position: 'fixed',
        zIndex: '999',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          backgroundColor: 'white',
          position: 'relative',
        }}>
          <CircularProgress size={100} thickness={6} />
          {showTime &&
          <div style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.5em',
            fontWeight: 'bold',
          }}>
            {seconds}
          </div>}
        </div>
      </Item>
    </Modal>
  );
}

export default ProgressControl;