import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import TabPanel from '../../../components/TabPanel';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { fetchGet } from '../../../services/GettingData';
import ErrorDialog from '../../../components/ErrorDialog';
import Header from '../../../components/HeaderComponent';
import MoleculeView from '../../../components/MoleculeView';
import ProgressControl from '../../../components/ProgressControl';
import NotAuthorizedError from '../../../schemas/Exception/NotAuthorizedError';
import ExpiredAccountError from '../../../schemas/Exception/ExpiredAccountError';
import { ICompoundSearchModel } from '../../../schemas/Compound/ICompoundSearchModel';
import { ICompoundSearchResponse } from '../../../schemas/Compound/ICompoundSearchResponse';

import {IPublicationSearchResponse} from '../../../schemas/Publication/IPublicationSearchResponse';
import '../../../App.css';
import Accept from '../../../schemas/Compound/Accept';
import { Item, Item2 } from '../../../styles';
//import ISession from '../../../schemas/Login/ISession';
import IProgressInfo from '../../../schemas/Compound/IProgressInfo';
import IFinishInfo from '../../../schemas/Compound/IFinishInfo';
import Config from '../../../config.json';


import WebSocketConnection from '../WebSocketConnection';
import { SearchStatus } from '../../../schemas/Compound/SearchStatus';

type PublicationSearchProps = RouteComponentProps & { publicationId?: string };
// type PublicationSearchState = {
//     searchBy: number,
//     urn: string,
//     journal: string,
//     year: string,
//     page: string,
//     publicationSearchResponse?: IPublicationSearchResponse,
//     compoundsSearchResponse?: ICompoundSearchResponse,
//     unassignedSpectrumResponse?: IPublicationSpectrumResponse,
//     isFound: boolean,
//     isLoading: boolean,
//     isLoadingUnassignedSpectrum: boolean,
//     isErrorDialogOpened: boolean,
//     errorMessage: string,
//     currentPage: number,
//     unassignedSpectrumCurrentPage: number,
//     session?: ISession,
// };



export const PublicationSearchPage: React.FC<PublicationSearchProps> = (props) => {

    // const [publicationSearchResponse, setPublicationSearchResponse] = React.useState<IPublicationSearchResponse>(props.publicationId ? {
    //     id: props.publicationId,
    //     urn: '',
    //     journal: '',
    //     authors: '',
    //     year: '',
    //     title: '',
    //     url: ''
    // } : undefined);
    const [publicationSearchResponse, setPublicationSearchResponse] = React.useState<IPublicationSearchResponse>();

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isFound, setFound] = React.useState<boolean>(false);
    const [urn, setUrn] = React.useState<string>('');
    const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [searchBy, setSearchBy] = React.useState<number>(0);
    const [isLoadingUnassignedSpectrum, setLoadingUnassignedSpectrum] = React.useState<boolean>(false);

    const [compoundSearchResponse, setCompoundSearchResponse] = React.useState<ICompoundSearchResponse>(undefined);
    const [unassignedSpectrumResponse, setUnassignedSpectrumResponse] = React.useState<IPublicationSearchResponse>(undefined);
    
    //const [unassignedSpectrumCurrentPage, setUnassignedSpectrumCurrentPage] = React.useState<number>(0);

    const [socketConnection, setSocketConnection] = React.useState<WebSocketConnection>();
    
    const [searchCompound, setSearchCompound] = React.useState<string>();
    const [searchCompoundStatus, setSearchCompoundStatus] = React.useState<SearchStatus>();
    const [currentCompoundPage, setCurrentCompoundPage] = React.useState<number>(1);
    const searchCompoundRef = React.useRef<string | undefined>(searchCompound);

    
    const [searchSpectrum, setSearchSpectrum] = React.useState<string>();
    const [searchSpectrumStatus, setSearchSpectrumStatus] = React.useState<SearchStatus>();
    const [currentSpectrumPage, setCurrentSpectrumPage] = React.useState<number>(1);
    const searchSpectrumRef = React.useRef<string | undefined>(searchSpectrum);







    // journal: '',
    // year: '',
    // page: '0',

    // isErrorDialogOpened: false,
    // currentPage: 1,
    // unassignedSpectrumCurrentPage: 1,
    // session: getSession(),




    let publicationId = new URLSearchParams(props.location.search).get('publicationId');
    if (!publicationId && (props as any).location?.state?.publicationId)
        publicationId = (props as any).location?.state?.publicationId;



    const spectrumColumn: GridColDef[] = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            hide: true
        },
        {
            field: 'spectrum_string',
            headerName: '',
            flex: 0.9,
            sortable: false,
            disableColumnMenu: true,
        },
    ];


    React.useEffect(() => {
        const fetchData = async () => {
            if (publicationSearchResponse?.id) {
                console.log('publicationSearchResponse');
                await publicationSearch();
            }
        };
        fetchData();
    }, []);


    const closeErrorDialog = () => setErrorDialogOpened(false);


    const onError = (packet: any) => {
        console.log('/error', packet.error);
        showError(packet.error);
        setLoading(false);
    }


    const showError = (errorMessage: string) => {
        setErrorDialogOpened(false);
        setLoading(false);
        setErrorMessage(errorMessage);
    }


    const header = () => { return (<div style={{ height: '2em', alignItems: 'center' }}><span></span></div>); }

    const handleChangeSearchBy = (event: React.SyntheticEvent, newSearchBy: number) => {
        setSearchBy(newSearchBy);
    }


    const handleChangeDoi = (newDoi: string) => {
        setPublicationSearchResponse(undefined);
        setUrn(newDoi.trim().replace('https://doi.org/', '').replace('http://doi.org/', '').toLowerCase());
    }


    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const gotoSpectrum = (spectrumId: string) => {
        window.open('/spectrum/' + spectrumId, '_blank');
    }


    const pageChangeHandle = (event: React.ChangeEvent<unknown>, value: number) => {

        get(searchCompound, value);
        //publicationSpectraSearch(value);
    }


    const unassignedSpectrumPageChangeHandle = (event: React.ChangeEvent<unknown>, value: number) => {
        //unassignedSpectrumSearch(value);
        get(searchSpectrum, value)
    }


    const getPublicationByDoi = async (doi: string): Promise<IPublicationSearchResponse> => {
        setFound(false);

        const publicationResponse = await fetchGet('/publication/urn/' + doi, true, true);
        if (!publicationResponse.ok)
            if (publicationResponse.status === 403) {
                (props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
            }
            else {
                if (publicationResponse.status === 404)
                    throw 'publication ' + doi + ' not found';
                throw publicationResponse.json();
            }
        const publication = await publicationResponse.json();
        if (!publication)
            throw 'publication not found by doi ' + doi;
        return publication;
    }


    const getPublicationById = async (id: string): Promise<IPublicationSearchResponse> => {
        setFound(false);
        const publicationResponse = await fetchGet('/publication/id/' + id, true, true);
        if (!publicationResponse.ok)
            if (publicationResponse.status === 403) {
                (props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
            }
            else {
                if (publicationResponse.status === 404)
                    throw 'publication ' + id + ' not found';
                throw publicationResponse.json();
            }
        const publication = await publicationResponse.json();
        if (!publication)
            throw 'publication not found by id ' + id;
        return publication;
    }


    // const getSpectrumByPublication = async (publicationId: string, spectrumCurrentPage: number): 
    //     Promise<IPublicationSearchResponse> => {
    //     const publicationSpectrumCompoundResponse = await fetchGet('/spectrum/search?publication_id=' + publicationId + '&null_compounds=true&page=' + spectrumCurrentPage.toString() + '&size=10', true, true);
    //     if (!publicationSpectrumCompoundResponse.ok)
    //         throw publicationSpectrumCompoundResponse.message;
    //     return await publicationSpectrumCompoundResponse.json();
    // }


    // const getCompoundsByPublication = async (publicationId: string, currentPage: number): Promise<ICompoundSearchResponse> => {
    //     const publicationCompoundResponse = await fetchGet('/compound/publication/' + publicationId + '?page=' + currentPage.toString() + '&size=10', true, true);
    //     if (!publicationCompoundResponse.ok)
    //         throw publicationCompoundResponse.message;
    //     return await publicationCompoundResponse.json();
    // }


    const publicationSearch = async () => {
        try {
            setLoading(true);

            if (publicationSearchResponse?.id) {
                const publication = await getPublicationById(publicationSearchResponse?.id);
                setPublicationSearchResponse(publication);
                setFound(true);
                setLoading(false);
                //}), async ()=> {
                //  await publicationSpectraSearch(1);
                //  await unassignedSpectrumSearch(1)
                //});
            }
            else {
                const publication = await getPublicationByDoi(urn);
                setPublicationSearchResponse(publication);
                setFound(true);
                setUrn(urn);
                setLoading(false);

                // async ()=> {
                //  await this.publicationSpectraSearch(1);
                //  await this.unassignedSpectrumSearch(1)
                //});
            }
        }
        catch (e: any) {
            if (e instanceof ExpiredAccountError)
                (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
            if (e instanceof NotAuthorizedError) {
                (props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
            } else {
                console.log('compoundSearchResponse',compoundSearchResponse);
                showError(e.toString());
                setPublicationSearchResponse(undefined);
                setCompoundSearchResponse(undefined);
                setLoading(false);
            }
        }
    }


    // const publicationSpectraSearch = async (currentPage: number) => {
    //     try {
    //         setLoading(true);
    //         //const compounds = await getCompoundsByPublication(publicationSearchResponse!.id, currentPage);
    //         get(currentPage);

    //         //console.log('compounds', compounds);
    //         //setCompoundSearchResponse(compounds);
            
    //         setFound(true);
    //         setUrn(urn);
    //         //setCurrentPage(compounds.pagination.page);
    //     }
    //     catch (e: any) {
    //         if (e instanceof NotAuthorizedError) {
    //             (props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
    //         } else {
    //             showError(e.toString());
    //             setPublicationSearchResponse(undefined);
    //             setCompoundSearchResponse(undefined);
    //             setLoading(false);
    //             setFound(false);
    //             setCurrentPage(1);
    //         }
    //     }
    // }


    // const unassignedSpectrumSearch = async (currentPage: number) => {
    //     try {
    //         if (!publicationSearchResponse?.id) return;
    //         setLoadingUnassignedSpectrum(true);



    //         const spectrum = await getSpectrumByPublication(publicationSearchResponse?.id as string, currentPage);
    //         setUnassignedSpectrumResponse(spectrum);
    //         setFound(true);
    //         setLoadingUnassignedSpectrum(false);
    //         setUnassignedSpectrumCurrentPage(currentPage);
    //     }
    //     catch (e: any) {
    //         if (e instanceof NotAuthorizedError) {
    //             (props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
    //         } else {
    //             if (e)
    //                 showError(e.toString());
    //         }
    //     }
    // }

    const onFinish = (data: IFinishInfo) => {
        console.log('/search/finish trigered', data);
        console.log('searchCompound', searchCompound);
        console.log('searchCompoundRef', searchCompoundRef);

        
        if (data.id === searchCompoundRef.current) {
          console.log('/search/finish is obtained ');
          setSearchCompoundStatus(SearchStatus.Finished);//A12
        }
        if (data.id === searchSpectrumRef.current) {
            console.log('/search/finish  S is obtained ');
            setSearchSpectrumStatus(SearchStatus.Finished);//A12
          }
  
    }

    const onProgress = (progress: IProgressInfo) => {
        //console.log('/search/progress', progress);
        //console.log('/search/progress searchId=', searchRef.current);
        if (progress.search_id === searchCompoundRef.current) {
          console.log('progress with this query', progress.progress.n_items);
          if (searchCompoundStatus !== SearchStatus.InProgress) {
            console.log('change status to in progress');
            //setInProgressFound(progress.progress.n_items);
            setSearchCompoundStatus(SearchStatus.InProgress);

          }
        }
    }

    const get = (search: string, page: number) => {
        if (search && socketConnection) {
          const connection = socketConnection.getSocket();
          console.log('emit get with a page', search, page);
          connection.emit("/search/get", {
            "id": search,
            "page": page, 
            'per_page': Config.itemsPerPage
          });
        }
    }

    const onGet = (data: any) => {
        console.log('got data', data);
        if (data.id === searchCompoundRef.current) {
            console.log('got on data about compounds', data);
            setCompoundSearchResponse(data);
        }
        if (data.id === searchSpectrumRef.current) {
            console.log('got on data about UnassignedSpectrum', data);
            setUnassignedSpectrumResponse(data);
        }
 
        //setSpectrumSearchResult(data);
        setFound(true);
        setLoading(false);

      }

      

    React.useEffect(() => {
        if (searchCompoundStatus === SearchStatus.Finished)
            get(searchCompoundRef.current, currentCompoundPage);
        if (searchCompoundStatus === SearchStatus.InProgress) {
            setCurrentCompoundPage(1);
            console.log('search status became in progress');
            get(searchCompoundRef.current, 1);
        }
    
      }, [searchCompoundStatus, currentCompoundPage]);

      
      React.useEffect(() => {
        if (searchSpectrumStatus === SearchStatus.Finished) {
          get(searchSpectrumRef.current, currentSpectrumPage);
        }
        if (searchSpectrumStatus === SearchStatus.InProgress) {
          setCurrentSpectrumPage(1);
          console.log('search status became in progress');
          get(searchSpectrumRef.current, 1);
        }
      }, [searchSpectrumStatus, currentSpectrumPage]);


    const onAccept = (packet: Accept, connection: any) => {
       
        if (packet.type === 'publication_compound') {
            searchCompoundRef.current = packet.id;
            setSearchCompound(packet.id);
            console.log('/search/accepted compound', packet);
        }

        if (packet.type === 'publication_spectrum') {
            searchSpectrumRef.current = packet.id;
            console.log('/search/accepted specrum', packet);
            setSearchSpectrum(packet.id);
        }


        connection.on('/search/get', (data) => onGet(data));
        connection.on('/search/progress', (data) => onProgress(data));
        connection.on('/search/finish', (data) => onFinish(data));
    }
    

    React.useEffect(() => {
        setSocketConnection(new WebSocketConnection());
    }, [])

    //const startSearchPublication = async () => { await publicationSearch(); }

    React.useEffect(() => {
        if (socketConnection) {
          const connection = socketConnection.getSocket();
          connection.on('/error', (data) => onError(data));
          connection.on('/search/accepted', (packet) => onAccept(packet, connection));
          return () => {
            connection.off('/search/accepted', onAccept);
          };
        }
      }, [socketConnection])


    
    const startSearchPublication = async (): Promise<boolean> => {
        if (socketConnection) {
          setSearchCompoundStatus(SearchStatus.Started);
          setLoading(true);
          console.log('emit start search urn', urn);
          socketConnection.getSocket().emit("/search/start", {
            "type": "publication_compound",
            "params": {
                urn: urn
            }
          });
          socketConnection.getSocket().emit("/search/start", {
            "type": "publication_spectrum",
            "params": {
                urn: urn
            }
          });
        }
        return true;
      }


    const helperDoiExamples = () => {
        const helperTexts = [
          {
            id: 1,
            value: "10.1021/jo502618g"
          }];

        return helperTexts.map((text) => (
          <span style={{ cursor: 'pointer' }} onClick={()=> {
            handleChangeDoi(text.value)
          }} key={text.id} data-id={text.id} className="helper-text">
            {'ex. ' + text.value}
          </span>
        ));
      }


    return (
        <Grid container spacing={0} className='main-frame'>
            <ProgressControl isLoading={isLoading || isLoadingUnassignedSpectrum} />
            <Grid xs={12}>
                <Item2><div style={{ height: '2em' }}></div></Item2>
            </Grid>
            <Grid xs={12}>
                <Header title='Publication Search' showLogin={true} helpAddress='help#wips' />
            </Grid>
            <ErrorDialog isDialogOpened={isErrorDialogOpened}
                errorMessage={errorMessage}
                onClose={closeErrorDialog} />

            <>
                <Grid xs={12} style={{ marginTop: '4em' }}>
                    <div>{header()}
                        <Tabs value={searchBy}
                            onChange={handleChangeSearchBy}
                            style={{ padding: '0px' }}
                            aria-label="basic tabs">
                            <Tab label="Search by DOI" {...a11yProps(0)} />
                        </Tabs>
                        <TabPanel value={searchBy} index={0}>
                            <Item style={{ marginTop: '1em' }}>
                                <Grid container xs={12} style={{ marginTop: '2em' }} >
                                    <Grid xs={1}>
                                        <div style={{ marginTop: '1em' }}>
                                            DOI
                                        </div>
                                    </Grid>
                                    <Grid xs={10} md={3}>
                                        <TextField
                                            type="text"
                                            sx={{
                                                input: { "&::placeholder": { opacity: .5, }, }, label: { color: 'blue' }
                                            }}
                                            id="doi-query-text"
                                            label=""
                                            fullWidth
                                            onChange={(e) => { handleChangeDoi(e.target.value) }}
                                            //defaultValue={urn}
                                            value={urn}
                                            helperText={helperDoiExamples()}
                                        />
                                    </Grid>
                                </Grid>
                            </Item>
                        </TabPanel>
                    </div>
                </Grid>
                <Grid xs={12} style={{ marginTop: '6em' }}>
                    <Button disabled={!urn || isLoading} onClick={() => startSearchPublication()} variant="contained">Search</Button>
                </Grid>

                <Grid container md={12} xs={12}>
                    {isFound &&
                        <Grid md={12} xs={12}>
                            <Item id='block-stat'>
                                <div className='molecules-count'> {!isFound ? '' : <>Found publication with {compoundSearchResponse?.total} compounds:</>}</div>
                            </Item>
                        </Grid>
                    }
                </Grid>
            </>

            {isFound && !isLoading && <Grid md={12} style={{ marginTop: '2em', marginBottom: '0em' }}>
                {publicationSearchResponse && publicationSearchResponse.url}
            </Grid >
            }


            {isFound &&
                (isLoading ? <></> :
                    (<Grid container style={{ marginTop: '2em' }}>
                        {compoundSearchResponse?.items.map((compound: ICompoundSearchModel, index: number) => {
                            return (
                                <Grid md={12} container spacing={0} key={index}>
                                    <Grid md={3} style={{ marginBottom: '.5em', minWidth: "250px" }}>
                                        <Item key={compound.id} style={{ width: "250px", marginRight: 'auto' }}>
                                            <MoleculeView link={''}
                                                moleculeId={compound.id}
                                                isMoleculeInContainer={true}
                                                svgContent={compound.svg} />
                                        </Item>
                                        <div>{compound.compound_representation}</div>
                                    </Grid>
                                    <Grid md={9}>
                                        <Grid container xs={12} style={{ marginLeft: '1em' }}>
                                            <DataGrid
                                                disableColumnMenu
                                                sx={{
                                                    '.MuiDataGrid-cell:focus': {
                                                        outline: 'none'
                                                    },
                                                    '& .MuiDataGrid-row:hover': {
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                                autoHeight
                                                onCellDoubleClick={(params, event) => {
                                                    if (!event.ctrlKey) {
                                                        event.defaultMuiPrevented = true;
                                                    }
                                                    if (params.field === 'spectrum_string') gotoSpectrum(params.row.id);
                                                }}
                                                rows={compound.spectra}
                                                columns={spectrumColumn}
                                                disableSelectionOnClick
                                            /></Grid>
                                    </Grid>
                                </Grid>)
                        })}

                        {!isLoading &&
                            <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
                                <Pagination style={{ marginTop: '1em' }}
                                    count={Math.ceil((compoundSearchResponse ? compoundSearchResponse.total : 0) /
                                        (compoundSearchResponse ? compoundSearchResponse.pagination.per_page : Config.itemsPerPage))}
                                    page={compoundSearchResponse?.pagination.page} onChange={(e, v) => pageChangeHandle(e, v)} />
                            </Grid>}

                    </Grid>))
            }

            {isLoadingUnassignedSpectrum ? <></> :
                unassignedSpectrumResponse &&
                <Grid container style={{ marginTop: '2em', marginBottom: '2em', width: '100%' }}>
                    <Grid style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <h2>This publication also contains following spectra not assigned to any compound</h2>
                    </Grid>
                    {
                        <Grid container xs={12}>
                            <DataGrid
                                sx={{
                                    '.MuiDataGrid-cell:focus': {
                                        outline: 'none'
                                    },
                                    '& .MuiDataGrid-row:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                autoHeight
                                onCellDoubleClick={(params, event) => {
                                    if (!event.ctrlKey) {
                                        event.defaultMuiPrevented = true;
                                    }
                                    if (params.field === 'spectrum_string') gotoSpectrum(params.row.id);

                                }}
                                rows={unassignedSpectrumResponse?.items}
                                columns={spectrumColumn}
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                            />
                        </Grid>
                    }
                    {!isLoadingUnassignedSpectrum && unassignedSpectrumResponse &&
                        <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
                            <Pagination style={{ marginTop: '1em' }}
                                count={Math.ceil((unassignedSpectrumResponse ? unassignedSpectrumResponse.total : 0) /
                                    (unassignedSpectrumResponse ? 
                                        unassignedSpectrumResponse.pagination.per_page : Config.itemsPerPage))}
                                page={unassignedSpectrumResponse?.pagination.page} onChange={(e, v) => unassignedSpectrumPageChangeHandle(e, v)} />
                        </Grid>}
                </Grid>
            }
        </Grid>
    );
}

export default PublicationSearchPage;