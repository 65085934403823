import React from 'react';
import { Link, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { SelectChangeEvent } from '@mui/material/Select';
import { Table, TableBody, TableCell, TableRow, TableContainer, Paper, TableHead } from '@mui/material';


import '../../../App.css';
import Doi from '../../../components/Doi';
import { Item, Item2 } from '../../../styles';
import AppSettings from '../../../API/appSettings';
import { getPreview } from '../../../services/Spectrum';
import { molToSvg } from '../../../services/NameToMolecule';
import Header from '../../../components/HeaderComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import MoleculeView from '../../../components/MoleculeView';
import ProgressControl from '../../../components/ProgressControl';
import { SolventControl } from '../../../components/SolventControl';
import TimeoutError from '../../../schemas/Exception/TimeoutError';
import HelpIconWithHint from '../../../components/HelpIconWithHint';
import Config from '../../../config.json';

import InternalError from '../../../schemas/Exception/InternalError';
import { fetchPost, reportError } from '../../../services/GettingData';
import NotAuthorizedError from "../../../schemas/Exception/NotAuthorizedError";
import ISpectrumPreviewModel from '../../../schemas/Spectrum/ISpectrumPreviewModel';
import IReportSpectraErrorModel from '../../../schemas/IReportSpectraErrorModel';
import ReportConfirmationDialog from '../../../components/Dialog/ReportConfirmationDialog';
import IGetResponse from '../../../schemas/Spectrum/IGetResponse';

import ExpiredAccountError from '../../../schemas/Exception/ExpiredAccountError';
import IMolWeightFilter from '../../../schemas/IMolWeightFilter';
import { SpectrumSearchMode } from '../../../schemas/Spectrum/SpectrumSearchMode';

import WebSocketConnection from '../WebSocketConnection';
import Accept from '../../../schemas/Compound/Accept';
import { SearchStatus } from '../../../schemas/Compound/SearchStatus';
import IProgressInfo from '../../../schemas/Compound/IProgressInfo';
import IFinishInfo from '../../../schemas/Compound/IFinishInfo';



type ISpectrumPL = {
  location: SpectrumSearchPS
};

type SpectrumSearchPS = {
  state: {
    spectrumPreview: ISpectrumPreviewModel
  }
}

export const SpectrumSearchResultPage: React.FC<ISpectrumPL> = (props) => {
  const getSpectraSVG = (): string => {
    let svg = localStorage.getItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.SPECTRA) || '';
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.SPECTRA);
    return svg
  }


  const [currentPage, setCurrentPage] = React.useState<number>((props as any).match.params['page']);
  const [spectrumPreview, setSpectrumPreview] = React.useState<ISpectrumPreviewModel>(props.location.state.spectrumPreview);
  const [pageSize] = React.useState<number>(10);
  const [spectrumSearchResult, setSpectrumSearchResult] = React.useState<IGetResponse>();
  const [spectrumRows, setSpectrumRows] = React.useState<any[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isConfirmationDialogOpened, setConfirmationDialogOpened] = React.useState<boolean>(false);
  const [spectrumParams, setSpectrumParams] = React.useState<{}>({});
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [inProgressFound, setInProgressFound] = React.useState<number>(0);
  const [spectraSVG, setSpectraSVG] = React.useState<string>(getSpectraSVG());
  const [spectraMolecule, setSpectraMolecule] = React.useState<string>('');
  const [moleculeWeightFilters, setMoleculeWeightFilters] = React.useState<IMolWeightFilter[]>(props.location.state.spectrumPreview?.moleculeWeightFilters ?? []);
  const [isMassFilterDialogOpen, setMassFilterDialogOpen] = React.useState(false);
  const [isRegionDialogOpen, setRegionDialogOpen] = React.useState(false);
  const [minMass, setMinMass] = React.useState("");
  const [maxMass, setMaxMass] = React.useState("");
  const [minRegion, setMinRegion] = React.useState("");
  const [maxRegion, setMaxRegion] = React.useState("");
  const [isApplyClicked, setApplyClicked] = React.useState(0);
  const [mandatoryPeaks, setMandatoryPeaks] = React.useState<number[]>(props.location.state.spectrumPreview?.mandatoryPeaks ?? []);
  const [excludedPeaks, setExcludedPeaks] = React.useState<number[]>([]);
  const [solvent, setSolvent] = React.useState<string | undefined>(props.location.state.spectrumPreview?.solvent ?? undefined);
  const [noShiftRegions, setNoShiftRegions] = React.useState<IMolWeightFilter[]>(props.location.state.spectrumPreview?.noShiftRegions ?? []);

  const [searchStatus, setSearchStatus] = React.useState<SearchStatus>();//TODO move to search
  const [socketConnection, setSocketConnection] = React.useState<WebSocketConnection>();
  const [search, setSearch] = React.useState<string>();
  const searchRef = React.useRef<string | undefined>(search);

  let history = useHistory();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!spectraSVG && props.location.state.spectrumPreview.structure) {
        const optionalSvg = await molToSvg(props.location.state.spectrumPreview.structure);
        setSpectraSVG(optionalSvg);
      }
    };
    fetchData().catch(console.error);
  }, [props.location.state.spectrumPreview.structure]);

  const pageChangeHandle = (event: React.ChangeEvent<unknown> | undefined, value: number) => {
    setCurrentPage(value);
    console.log('page handel fired', spectrumPreview)
    history.push({
      pathname: '/spectrum-search-result/' + value.toString(),
      state: {
        spectrumPreview: {
          ...spectrumPreview,
          peaks: props.location.state.spectrumPreview.peaks.filter(peak => !excludedPeaks.includes(peak)),
        },
        currentPage: value
      }
    });
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0,
      hide: true
    },
    {
      field: 'svgContent',
      headerName: 'Compound',
      width: 170,
      sortable: false,
      editable: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value !== 'data:image/svg+xml,null')
          return <div><a href={'/exact-search/' + params.row.compound_id}><img alt='' style={{ margin: '10px' }} width="150px" src={params.value} /></a></div>
        else return <div style={{ minHeight: '52px' }}></div>
      },
    },
    {
      field: 'spectrum_string',
      headerName: 'Spectrum string',
      flex: 0.5,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      cellClassName: 'spectrum_string',
    },
    {
      field: 'quality',
      headerName: 'Quality',
      width: 100,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'overall_quality',
      headerName: 'Overall quality',
      width: 100,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'urn',
      headerName: 'Reference',
      width: 250,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return Doi.DoiShort(params.value)
      },
    },
    {
      field: 'report',
      headerName: '',
      width: 150,
      editable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            className='report-error-spectrum'
            onClick={(e) => onReportClick(params)}
            variant='outlined'
            sx={{ color: '#505050', borderColor: '#505050' }}
          >Report Error</Button>
        );
      },
    },
  ];


  const onProgress = (progress: IProgressInfo) => {
    //console.log('/search/progress', progress);
    //console.log('/search/progress searchId=', searchRef.current);
    if (progress.search_id === searchRef.current) {
      console.log('progress with this query', progress.progress.n_items);
      if (searchStatus !== SearchStatus.InProgress) {
        console.log('change status to in progress');
        setInProgressFound(progress.progress.n_items);
        setSearchStatus(SearchStatus.InProgress);
      }
    }
  
  }
  React.useEffect(() => {
      
    if (socketConnection) {
      console.log('socket not null, subscribtion is started');
      const connection = socketConnection.getSocket();
      connection.on('/error', (data) => onError(data));
      //connection.on('/search/finish', (packet) => onFinish(packet));
      //connection.on('/search/progress', (packet) => onProgress(packet));
      //subscribeToEvent('/search/progress', (packet) =>{ 
      //  onProgress(packet)});
      connection.on('/search/accepted', (packet) => onAccept(packet, connection));
      //subscribeToEvent('/search/accepted', (packet) => onAccept(packet));
      console.log('subscribtion has finished');

      return () => {
        //connection.off('/search/progress', onProgress);
        connection.off('/search/accepted', onAccept);
        //connection.off('/search/finish', onFinish);
      };
    }
  }, [socketConnection])


  React.useEffect(() => {
    setSocketConnection(new WebSocketConnection());
}, [])


const onError = (packet: any) => {
  console.log('/error', packet.error);
  showError(packet.error);
  setLoading(false);
  //setSearch(undefined);
  //setSearchId(null);
}

  const onGet = (data: IGetResponse) => {
    console.log('obtained data', data);
    setSpectrumSearchResult(data);
    let localSpectrumRows = [];
    if (data?.items)
      for (let i = 0; i < data.items.length; i++) {
        let some = {
          ...data.items[i],
          svgContent: 'data:image/svg+xml,' + encodeURIComponent(data?.items[i].molecule_svg)};
        localSpectrumRows.push(some);
      }
    setSpectrumRows(localSpectrumRows);
    setLoading(false);
  }


const get = (currentPage: number) => {
  if (search && socketConnection) {
    const connection = socketConnection.getSocket();
    console.log('emit get with a page', search, currentPage);
    connection.emit("/search/get", {
      "id": search,
      "page": currentPage, 
      'per_page': Config.itemsPerPage
    });
  }
}


  const onFinish = (packet: IFinishInfo, searchId: string) => {
    if (packet.id === searchId) {
      console.log('/search/finish is obtained ');
      setSearchStatus(SearchStatus.Finished);
    }
  }

  const startStructSearch = async (spectraMolecule: string): Promise<boolean> => {
    if (socketConnection) {
      setSearchStatus(SearchStatus.Started);
      setLoading(true);
      socketConnection.getSocket().emit("/search/start", {
        "type": "spectrum",
        "params": {
          'type': props.location.state.spectrumPreview.spectrum_type,
          'peaks': props.location.state.spectrumPreview.peaks,
          'include_null_quality': false,
          'solvent': solvent === '' ? undefined : solvent,
          'structure': spectraMolecule,
          'weights': moleculeWeightFilters,
          'mode': spectrumPreview.spectrumSearchMode,
          'mandatory_peaks': mandatoryPeaks,
          'no_shift_regions': noShiftRegions
        }
      });
    }
    return true;
  }


const onAccept = (packet: Accept, connection: any) => {
    console.log('/search/accepted happens', packet.id);
    connection.on('/search/get', (data) => onGet(data));
    connection.on('/search/progress', (data) => onProgress(data));
    connection.on('/search/finish', (packet) => onFinish(packet, packet.id));
    setSearch(packet.id);
}

  React.useEffect(() => {
    searchRef.current = search;
  }, [search]);

  
  const openAddWeightDialog = () => {
    setMinMass('');
    setMaxMass('');
    setMassFilterDialogOpen(true);
  };


  const openAddRegionDialog = () => {
    setMinRegion('');
    setMaxRegion('');
    setRegionDialogOpen(true);
  };


  React.useEffect(() => {
    if (socketConnection) {
      console.log('use effect fired for currentPage, isApplyClicked  ', currentPage, isApplyClicked, socketConnection);
      let _spectraMolecule: string | undefined = undefined;
      if (!spectraMolecule) {
        _spectraMolecule = getSpectraMolecule();
        setSpectraMolecule(_spectraMolecule);
      }
      else _spectraMolecule = spectraMolecule;
      startStructSearch(spectraMolecule as string);
    }
  }, [currentPage, isApplyClicked, socketConnection]);


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('get preview will be called');
        const spectrumPreview = await getPreview(props.location.state.spectrumPreview);
        setSpectrumPreview(spectrumPreview);
      }
      catch (e: any) {
        console.log('get preview failed, with exception: ' + e);

        if (e instanceof NotAuthorizedError)
          history.push({ pathname: '/login', state: { backTo: '/spectrum-search', welcomeMessage: true } });
        if (e instanceof ExpiredAccountError)
          history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });

        if (e instanceof TimeoutError || e instanceof ReferenceError || e instanceof InternalError)
          showError(e.toString());

        console.error(e);
        showError(e.toString());
      }
    }
    fetchData().catch(console.error);
  }, []);


  const onReportClick = async (params: any) => {
    setSpectrumParams(params);
    setConfirmationDialogOpened(true);
  }


  React.useEffect(() => {
    if (searchStatus === SearchStatus.Finished) {
      console.log('get got');
      get(currentPage);
    }
    if (searchStatus === SearchStatus.InProgress) {
      setCurrentPage(1);
      console.log('search status became in progress');
      get(1);
    }

  }, [searchStatus, currentPage]);


  const onCloseErrorDialog = () => {
    setErrorDialogOpened(false);
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
  }


  const getSpectraMolecule = (): string => {
    let structure = localStorage.getItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.SPECTRA) || '';
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.SPECTRA);
    if (!structure)
      structure = props.location.state.spectrumPreview.structure;
    return structure;
  }


  const onCloseDialog = async (confirmed: boolean, mistakeComment: string) => {
    setConfirmationDialogOpened(false);
    let params = spectrumParams as any;
    if (confirmed && params.row) {
      const spectrError: IReportSpectraErrorModel = {
        spectrum_id: params.row.id,
        spectrum_string: params.row.spectrum_string,
        comment: mistakeComment
      }
      const errors = [spectrError];
      await reportError(JSON.stringify(errors));
    }
    setSpectrumParams({});
  }


  const saveSpectraFilter = () =>
    localStorage.setItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.SPECTRA, spectraMolecule || '');


  const applyFilters = () => {
    console.log('before apply filters', spectrumPreview);
    pageChangeHandle(undefined, 1);
    setApplyClicked(isApplyClicked + 1);
  }


  const addMassEntity = () => {
    setMoleculeWeightFilters(prevFilters => [
      ...prevFilters,
      {
        start: parseInt(minMass, 10),
        end: parseInt(maxMass, 10),
      },
    ]);
    setMassFilterDialogOpen(false);
  };


  const addRegionEntity = () => {
    setNoShiftRegions(prevFilters => [
      ...prevFilters,
      {
        start: parseInt(minRegion, 10),
        end: parseInt(maxRegion, 10),
      },
    ]);
    setRegionDialogOpen(false);
  };


  const onDelPeak = (peak: number) => {
    console.log('delete peak with id');
    setExcludedPeaks([...excludedPeaks, peak]);
  }


  const handleMandatoryPeaksChange = (peak: number) => {
    setMandatoryPeaks(prev => {
      const isPeakPresent = prev.includes(peak);
      if (isPeakPresent) {
        return prev.filter(p => p !== peak);
      } else
        return [...prev, peak];
    });
  }


  const onDelMWeight = (toDelete: any) =>
    setMoleculeWeightFilters((prev) => prev.filter((el) => el.start + '-' + el.end !== toDelete))


  const onDelRegion = (toDelete: any) =>
    setNoShiftRegions((prev) => prev.filter((el) => el.start + '-' + el.end !== toDelete))


  const changeSolventHandler = (e: SelectChangeEvent) => {
    setSolvent(e.target.value === 'any' ? '' : e.target.value);
  }

  const formatSpectrumSearchMode = (spectrumSearchMode: string | undefined) => {
    return (spectrumSearchMode ?? '')
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const pngString = encodeURIComponent(spectrumPreview?.graph ? spectrumPreview.graph : '');
  const dataUri = `data:image/png;base64,${pngString}`;


  const gotoSpectr = (spectrumId: string, spectrumPreview?: ISpectrumPreviewModel) => {
    localStorage.setItem(spectrumId, JSON.stringify(spectrumPreview));
    window.open('/spectrum-compare/' + spectrumId, '_blank');
  }

  return (<Grid container spacing={0} className='main-frame'>
    <Grid md={12}>
      <Item2><div style={{ height: '2em' }}></div></Item2>
    </Grid>

    <Dialog open={isMassFilterDialogOpen} onClose={() => setMassFilterDialogOpen(false)}>
      <DialogTitle>Mass Region</DialogTitle>
      <DialogContent>
        <TextField name="minMass" label="Min Mass" required style={{ margin: '.5em' }} type="number"
          onChange={e => setMinMass(e.target.value)}
          inputProps={{
            step: "1",
            pattern: "\\d*"
          }} />
        <TextField name="maxMass" label="Max Mass" required style={{ margin: '.5em' }} type="number"
          onChange={e => setMaxMass(e.target.value)}
          inputProps={{
            step: "1",
            pattern: "\\d*"
          }} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!(maxMass && minMass) || (Number(minMass) >= Number(maxMass))} type="submit" onClick={addMassEntity}>Ok</Button>
        <Button variant='outlined' onClick={() => setMassFilterDialogOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={isRegionDialogOpen} onClose={() => setRegionDialogOpen(false)}>
      <DialogTitle>No Shift Region</DialogTitle>
      <DialogContent>
        <TextField name="minMass" label="Min no region" required style={{ margin: '.5em' }} type="number"
          onChange={e => setMinRegion(e.target.value)}
          inputProps={{
            step: "1",
            pattern: "\\d*"
          }} />
        <TextField name="maxMass" label="Max no region" required style={{ margin: '.5em' }} type="number"
          onChange={e => setMaxRegion(e.target.value)}
          inputProps={{
            step: "1",
            pattern: "\\d*"
          }} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!(maxRegion && minRegion) || (Number(minRegion) >= Number(maxRegion))} type="submit" onClick={addRegionEntity}>Ok</Button>
        <Button variant='outlined' onClick={() => setRegionDialogOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>

    <ReportConfirmationDialog isDialogOpened={isConfirmationDialogOpened} onClose={onCloseDialog} />
    <ErrorDialog isDialogOpened={isErrorDialogOpened}
      errorMessage={errorMessage}
      warningMessage={''}
      onClose={onCloseErrorDialog} />
    <Grid xs={12}>
      <Header title={formatSpectrumSearchMode(spectrumPreview.spectrumSearchMode) + ' Spectrum Search'} helpAddress='help#htuss' showLogin={true} />
    </Grid>

    <Grid md={12} container spacing={0} style={{ marginTop: '4em' }}>
      <Grid
        spacing={0}
        md={3}
        style={{ minWidth: "270px" }}
        direction="column"
        alignItems="center"
        justifyContent="center">
        <div style={{ paddingBottom: '0em' }}><span className='box-title'>Substructure filter</span></div>
        <Item style={{ width: "250px", marginTop: '10px' }} onClick={() => { saveSpectraFilter() }}>
          <MoleculeView link={'/editor/spectra'} svgContent={spectraSVG} isMoleculeInContainer={true} spectrumPreview={{
            ...spectrumPreview!,
            solvent: solvent,
            moleculeWeightFilters: moleculeWeightFilters,
            mandatoryPeaks: mandatoryPeaks
          }
          } />
        </Item>
      </Grid>

      {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.REACTION_MIXTURE_MODE &&
        <Grid
          spacing={0}
          md={3}
          direction="column"
          alignItems="center"
          justifyContent="center">
          {spectrumPreview?.graph ?
            <>
              <div>Requested Spectrum</div>
              <Grid md={10}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Item style={{ width: '100%' }}>
                  <div className='graph-container' style={{ paddingBottom: '2px' }}>
                    <img alt=''
                      className='graph-qc'
                      src={dataUri}
                      style={{ maxWidth: '100%' }} />
                  </div>
                </Item>
              </Grid></> : ''}

          <div style={{ marginTop: '2em', paddingRight: '5em' }}>
            <SolventControl anyPossible={true}
              onChange={changeSolventHandler}
              selected={solvent} />
          </div>
        </Grid>}


      {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.EXACT_MODE &&
        <Grid
          spacing={0}
          md={3}
          direction="column"
          alignItems="center"
          justifyContent="center">
          {spectrumPreview?.graph ?
            <>
              <div>Requested Spectrum</div>
              <Grid md={10}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Item style={{ width: '100%' }}>
                  <div className='graph-container' style={{ paddingBottom: '2px' }}>
                    <img alt=''
                      className='graph-qc'
                      src={dataUri}
                      style={{ maxWidth: '100%' }} />
                  </div>
                </Item>
              </Grid></> : ''}

          <div style={{ marginTop: '2em', paddingRight: '5em' }}>
            <SolventControl anyPossible={true}
              onChange={changeSolventHandler}
              selected={solvent} />
          </div>

        </Grid>}


      {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.UNKNOWN_COMPOUND_MODE &&
        <Grid
          spacing={0}
          md={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ marginRight: '1em' }}>
          Query Peaks
          <Grid md={12} style={{ minHeight: '20px' }}>
            <TableContainer component={Paper} style={{ maxHeight: '280px', overflowY: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Peak ppm</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {spectrumPreview.peaks.filter(peak => !excludedPeaks.includes(peak)).sort((a, b) => a - b).map((peak, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ width: '50px', padding: '.5em' }}>{peak}</TableCell>
                      <TableCell style={{ width: '50px', padding: '.5em' }}>
                        <Button variant="outlined"
                          title='Remove'
                          style={{ width: '50px', padding: '.2em' }}
                          size="small"
                          onClick={() => {
                            onDelPeak(peak)
                          }}>X</Button>
                      </TableCell>    
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>}



      {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.UNKNOWN_COMPOUND_MODE &&
        <Grid
          spacing={0}
          md={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ marginRight: '1em' }}>
          No Shift Regions
          <Grid md={12} style={{ minHeight: '20px' }}>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {noShiftRegions.map((filter, index) => (
                    <TableRow key={index}>
                      <TableCell>{filter.start}</TableCell>
                      <TableCell>{filter.end}</TableCell>
                      <TableCell>
                        <Button variant="outlined"
                          title='Remove'
                          style={{ width: '50px' }}
                          size="small"
                          onClick={() => onDelRegion(filter.start + '-' + filter.end)}>X</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid md={12}>
            <Box display="flex" justifyContent="center">
              <Button style={{ marginTop: '1em', width: '180px' }} variant='outlined' onClick={openAddRegionDialog}>Add Region</Button>
            </Box>
          </Grid>
        </Grid>}


      <Grid container
        spacing={0}
        md={2} xs={12}
        direction="column">
        Molecular Weight Filter
        <Grid md={12} style={{ minHeight: '20px' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {moleculeWeightFilters.map((filter, index) => (
                  <TableRow key={index}>
                    <TableCell>{filter.start}</TableCell>
                    <TableCell>{filter.end}</TableCell>
                    <TableCell>
                      <Button variant="outlined"
                        title='Remove'
                        style={{ width: '50px' }}
                        size="small"
                        onClick={() => onDelMWeight(filter.start + '-' + filter.end)}>X</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid md={12}>
          <Box display="flex" justifyContent="center">
            <Button style={{ marginTop: '1em', width: '180px' }} variant='outlined' onClick={openAddWeightDialog}>Add Mass Region</Button>
          </Box>
        </Grid>

        <span style={{ fontSize: 'small', margin: '1em' }}>
          Only molecules with molar mass in the prescribed range(s) will be shown
        </span>
      </Grid>


      {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.REACTION_MIXTURE_MODE &&
        <Grid container
          spacing={0}
          md={2} xs={12}
          direction="column">
          Mandatory peak filter
          <Grid md={12} style={{ minHeight: '20px' }}>
            <TableContainer component={Paper} style={{ maxHeight: '280px', overflowY: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Peak position</TableCell>
                    <TableCell>Mark as mandatory</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {spectrumPreview?.peaks.sort((a, b) => a - b).map((peak, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ padding: '.2em', textAlign: 'center' }}>{peak}</TableCell>
                      <TableCell style={{ padding: '.2em', textAlign: 'center' }}>
                        <Checkbox checked={mandatoryPeaks.includes(peak)}
                          onChange={() => handleMandatoryPeaksChange(peak)}
                        /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid md={12}>
            <Box display="flex" justifyContent="center"></Box>
          </Grid>
          <span style={{ fontSize: 'small', margin: '1em' }}>
            Only spectra including all these peaks will be shown
          </span>
        </Grid>}


      <Grid container
        spacing={0}
        md={2} xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center">
        <Grid md={12} style={{ minWidth: '220px' }}>
          <Item2 style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px', marginTop: '1em' }}>
            <Link to={{ pathname: '/spectrum-search', state: {} }} className='MuiButton' >
              <Tooltip arrow title='start new search'>
                <Button variant="contained">New Search</Button>
              </Tooltip>
            </Link>
            <HelpIconWithHint title={''}
              text={'Drop results and submit new spectrum'}
              handleOpen={() => { }} />
          </Item2>
        </Grid>
        <Grid md={12} style={{ minWidth: '220px' }}>
          <Item2 style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
            <Link to={{
              pathname: '/spectrum-search',
              state: { 'spectrumPreview': props.location.state.spectrumPreview }
            }}
              className='MuiButton' >
              <Tooltip arrow title='modify query'>
                <Button variant="contained">Edit Search</Button>
              </Tooltip>
            </Link>
            <HelpIconWithHint title={''}
              text={'Drop results and modify the query'}
              handleOpen={() => { }} />
          </Item2>

          <Item2 style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
            <Button color="success" variant="contained" onClick={applyFilters}>Apply Filters</Button>
            <HelpIconWithHint title={''}
              text={'Apply extra filter [mass, structure, solvent, mandatory peaks]'}
              handleOpen={() => { }} />
          </Item2>
        </Grid>
      </Grid>
    </Grid>

    {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.UNKNOWN_COMPOUND_MODE &&
      <Grid container
            spacing={0}
            md={12}
            direction="column"
            alignItems="center"
            justifyContent="center" 
            marginTop={'2em'}>
        <Grid
          spacing={0}
          md={4}
          direction="column"
          alignItems="center"
          justifyContent="center">
          {spectrumPreview?.graph ?
            <>
              <div>Requested Spectrum</div>
              <Grid md={10}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Item style={{ width: '100%' }}>
                  <div className='graph-container' style={{ paddingBottom: '2px' }}>
                    <img alt=''
                      className='graph-qc'
                      src={dataUri}
                      style={{ maxWidth: '100%' }} />
                  </div>
                </Item>
              </Grid></> : ''}


          <Grid md={12} style={{ minHeight: '20px' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Peak ppm</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        
        </Grid>}




    <Grid xs={12} container style={{ marginTop: '2em' }}>
      <Grid xs={12} style={{ marginTop: '0em', padding: '0em' }} spacing={2}>
        <Item style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
          <Box sx={{ width: '100%' }}>
            {isLoading ? <ProgressControl isLoading={isLoading} /> :
              <DataGrid
                autoHeight
                getRowHeight={() => 'auto'}
                rows={spectrumRows ? spectrumRows : []}
                columns={columns}
                onCellDoubleClick={(params, event) => {
                  if (!event.ctrlKey) {
                    event.defaultMuiPrevented = true;
                  }
                  if (params.field === 'urn') Doi.gotoDoi(params.row.urn);
                  if (params.field === 'spectrum_string') gotoSpectr(params.row.id, spectrumPreview);
                }}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />}
          </Box>
        </Item>
      </Grid>

      <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
        {!isLoading && spectrumSearchResult?.items &&
          <Pagination style={{ marginTop: '1em' }}
            count={Math.ceil(spectrumSearchResult.total / spectrumSearchResult.pagination.per_page)}
            page={spectrumSearchResult.pagination.page} onChange={pageChangeHandle} />
        }
      </Grid>
    </Grid>
  </Grid>
  );
}
export default SpectrumSearchResultPage;